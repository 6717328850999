@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
/* IBM PLEX */
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;300;400;500;700&display=swap');

/* MONTSERRAT */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;700&display=swap');
@import 'react-datepicker/dist/react-datepicker.css';

body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.scrollbar::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  &-track {
    border-radius: 10px;
    background-color: #f5f5f7;
  }
  &-thumb {
    background-color: #909ebb;
    border-radius: 10px;
  }
}
